@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 300;
	src: url(./assets/fonts/noto-sans-kr/NotoSansKR-Light.woff2) format('woff2'),
		url(./assets/fonts/noto-sans-kr/NotoSansKR-Light.woff) format('woff'),
		url(./assets/fonts/noto-sans-kr/NotoSansKR-Light.otf) format('opentype');
}

@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 400;
	src: url(./assets/fonts/noto-sans-kr/NotoSansKR-Regular.woff2) format('woff2'),
		url(./assets/fonts/noto-sans-kr/NotoSansKR-Regular.woff) format('woff'),
		url(./assets/fonts/noto-sans-kr/NotoSansKR-Regular.otf) format('opentype');
}

@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 500;
	src: url(./assets/fonts/noto-sans-kr/NotoSansKR-Medium.woff2) format('woff2'),
		url(./assets/fonts/noto-sans-kr/NotoSansKR-Medium.woff) format('woff'),
		url(./assets/fonts/noto-sans-kr/NotoSansKR-Medium.otf) format('opentype');
}

@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 700;
	src: url(./assets/fonts/noto-sans-kr/NotoSansKR-Bold.woff2) format('woff2'),
		url(./assets/fonts/noto-sans-kr/NotoSansKR-Bold.woff) format('woff'),
		url(./assets/fonts/noto-sans-kr/NotoSansKR-Bold.otf) format('opentype');
}

@font-face {
	font-family: 'S-CoreDream';
	font-style: normal;
	font-weight: 500;
	src: url(./assets/fonts/s-core-dream/SCDream5.otf) format('opentype');
}

@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

@media only screen and (max-width: 767.98px) {
	html,
	body {
		width: 100%;
		height: 100vh;
	}
}

body {
	background-color: white;
	margin: 0;
	padding: 0;
	font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
	color: inherit;
	text-decoration: none;
}

.mt-5px {
	margin-top: 5px;
}
.mt-10px {
	margin-top: 10px;
}
.mt-15px {
	margin-top: 15px;
}
.mt-20px {
	margin-top: 20px;
}
.mt-24px {
	margin-top: 24px;
}
.mt-26px {
	margin-top: 26px;
}
.mt-29px {
	margin-top: 29px;
}
.mt-30px {
	margin-top: 30px;
}
.mt-34px {
	margin-top: 34px;
}
.mt-35px {
	margin-top: 35px;
}
.mt-36px {
	margin-top: 36px;
}
.mt-38px {
	margin-top: 38px;
}
.mt-40px {
	margin-top: 40px;
}
.mt-46px {
	margin-top: 46px;
}
.mt-50px {
	margin-top: 50px;
}
.mt-60px {
	margin-top: 60px;
}

.ml-10px {
	margin-left: 10px;
}

.mb-10px {
	margin-bottom: 10px;
}

/**
 * FullCalendar
 */

:root {
	--fc-button-bg-color: #ffffff;
	--fc-button-border-color: #b0b0b0;
	--fc-button-hover-bg-color: #e9e9e9;
	--fc-button-hover-border-color: #b0b0b0;
	--fc-button-text-color: #333333;
	--fc-button-active-bg-color: #0e498f;
	--fc-button-active-border-color: #0e498f;
}
.fc .fc-button-primary {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
}
.fc .fc-toolbar-title {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 28px;
	line-height: 34px;
	color: #000000;
}
.fc .fc-toolbar.fc-header-toolbar {
	flex-wrap: wrap;
}
.fc .fc-button-primary:disabled {
	background-color: #bbbbbb !important;
	color: #e8e8e8 !important;
}
.fc .fc-button-primary:not(:disabled).fc-button-active {
	color: #ffffff !important;
}
.fc .fc-button-primary:not(:disabled) {
	box-shadow: none !important;
}
.fc .fc-timegrid-slot-label {
	font-size: 13px;
}
.day-header {
	color: #666666;
}

.fc-daygrid-event.room_status_approved .fc-daygrid-event-dot {
	border-color: #3788d8;
}
.fc-daygrid-event.room_status_pending .fc-daygrid-event-dot {
	border-color: #fead0a;
}
.fc-daygrid-event.booking-disable {
	background-color: transparent;
	border: none;
}
.fc-daygrid-event.booking-fulled {
	background-color: transparent;
	border: none;
}
.fc-daygrid-event.booking-disable .fc-event-main {
	color: gray;
}
.fc-daygrid-event.booking-fulled .fc-event-main {
	color: gray;
}
.fc-timegrid-event.room_status_approved {
	border-color: #3788d8;
	background-color: #3788d8;
}
.fc-timegrid-event.room_status_pending {
	border-color: #fead0a;
	background-color: #fead0a;
}
.fc-list-event.room_status_approved .fc-list-event-dot {
	border-color: #3788d8;
}
.fc-list-event.room_status_pending .fc-list-event-dot {
	border-color: #fead0a;
}
.fc-list-event.booking-disable .fc-list-event-dot {
	display: none;
}
.fc-list-event.booking-fulled .fc-list-event-dot {
	display: none;
}

.fc-daygrid-event.product_status_approved {
	border-color: #3788d8;
	background-color: #3788d8;
}
.fc-daygrid-event.product_status_pending {
	border-color: #fead0a;
	background-color: #fead0a;
}
.fc-list-event.product_status_approved .fc-list-event-dot {
	border-color: #3788d8;
}
.fc-list-event.product_status_pending .fc-list-event-dot {
	border-color: #fead0a;
}

.fc-daygrid-event.consult_status_approved .fc-daygrid-event-dot {
	border-color: #3788d8;
}
.fc-daygrid-event.consult_status_pending .fc-daygrid-event-dot {
	border-color: #fead0a;
}
.fc-timegrid-event.consult_status_approved {
	border-color: #3788d8;
	background-color: #3788d8;
}
.fc-timegrid-event.consult_status_pending {
	border-color: #fead0a;
	background-color: #fead0a;
}
.fc-list-event.consult_status_approved .fc-list-event-dot {
	border-color: #3788d8;
}
.fc-list-event.consult_status_pending .fc-list-event-dot {
	border-color: #fead0a;
}
